import partesApi from '../../api/partes-api'
import router from '../../router'
import generalApi from '@/api/general-api'

const state = {
  itemsOperarios: [],
  totalItemsOperarios: 0,
  chargeList: 0,
  selectStatus: [],
}

const mutations = {
  setItemsOperarios(state, val) {
    state.itemsOperarios = val
  },
  setTotalItemsOperarios(state, val) {
    state.totalItemsOperarios = val
  },
  setChargeList(state, val) {
    state.chargeList = val
  },
  setSelectStatus(state, val) {
    state.selectStatus = val
  },
}

const actions = {
  getListOperarios({ commit }, {
    page, per_page, search = '', timestamp = '', user = '',
  }) {
    commit('app/loading', true, { root: true })
    partesApi.getListOperarios(page, per_page, search, timestamp, user)
      .then(
        response => {
          commit('setItemsOperarios', response.data.data)
          commit('setTotalItemsOperarios', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener el listado de partes de trabajo.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async generateMonthly({ commit }, {
    timestamp = '',
  }) {
    commit('app/loading', true, { root: true })
    await partesApi.generateMonthly(timestamp)
      .then(
        response => {
          commit('notifications', { title: 'Informes mensuales generados correctamente.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al generar informes mensuales.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  edit({ commit }, { user }) {
    commit('app/loading', true, { root: true })
    partesApi.edit(user)
      .then(
        () => {
          commit('notifications', { title: 'Jornada actualizada con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('setChargeList', 1)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar la jornada.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
    async getSelectStatus({ commit }) {
    await generalApi.status('journeyday')
      .then(
        response => {      
          commit('setSelectStatus', response.data)
        },
        () => {
          commit('notifications', { title: 'Error en la carga de estados.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
}

const getters = {
  getItemsOperarios: state => state.itemsOperarios,
  getTotalItemsOperarios: state => state.totalItemsOperarios,
  getChargeList: state => state.chargeList,
  getSelectStatus: state => { return state.selectStatus },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
