import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/categories'

export default {
  edit(id, category) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, category)
  },
  create(category) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, category)
  },
  getCategory(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  getList(page, per_page, search, department) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      page,
      per_page,
      search,
      department_id: department
    })
  },
  getListCreateProject(departments) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list/createProject`, {
      departments,
    })
  },
}
