import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/absences'
export default {
  edit(id, absence) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, absence)
  },
  create(absence) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, absence)
  },
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  getAbsence(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  // eslint-disable-next-line camelcase
  list(page, per_page, search, user_id, date_ini, date_fin, orderBy) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      page,
      per_page,
      search,
      user_id,
      date_ini,
      date_fin,
      orderBy,
    })
  },
}
