import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/users'
const RESOURCE_NAME_JOURNEY_DAY = '/journeyday'

export default {
  edit(id, user) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, user)
  },
  restartJourney(id, user) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME_JOURNEY_DAY}/reopen/${id}`, user)
  },
  checkJourney(id, user) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME_JOURNEY_DAY}/current/${id}`, user)
  },
  create(user) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, user)
  },
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  getUser(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  list(page, per_page, search, roles, active) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      page,
      per_page,
      search,
      roles,
      active,
    })
  },
}
