import traceabilitiesApi from '../../api/traceabilities-api'
import router from '@/router'
import machinesApi from '@/api/machines-api'

const state = {
  traceability: {},
  items: [],
  totalItems: 0,
  chargeList: 0,
}

const mutations = {
  setTraceability(state, val) {
    state.machine = val
  },
  setItems(state, val) {
    state.items = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
  setChargeList(state, val) {
    state.chargeList = val
  },
}

const actions = {
  getListTraceabilities({ commit }, {
    page, per_page, search = '', date_ini = '', date_fin = '', project = '', machine = '',
  }) {
    commit('app/loading', true, { root: true })
    traceabilitiesApi.getList(page, per_page, search, date_ini, date_fin, project, machine)
      .then(
        response => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener el listado de trazabilidades.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    traceabilitiesApi.delete(id)
      .then(
        () => {
          commit('notifications', { title: 'Trazabilidad eliminada con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('setChargeList', 1)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al eliminar la trazabilidad.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  edit({ commit }, { id, traceability }) {
    commit('app/loading', true, { root: true })
    traceabilitiesApi.edit(id, traceability)
      .then(
        () => {
          commit('notifications', { title: 'Trazabilidad actualizada con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('setChargeList', 1)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar la trazabilidad.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
}

const getters = {
  getTraceability: state => state.traceability,
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
  getChargeList: state => state.chargeList,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
