import departmentsApi from "@/api/departments-api";
import router from "@/router";

const state = {
  department: {},
  items: [],
  selectDepartments: [],
  showModalCreateDepartment: false,
};

const mutations = {
  setDepartment(state, val) {
    state.department = val;
  },
  setShowModalCreateDepartment(state, val) {
    state.showModalCreateDepartment = val;
  },
  setItems(state, val) {
    state.items = val;
  },
  setSelectDepartments(state, val) {
    state.selectDepartments = val;
  },
};

const actions = {
  async selectDepartments({ commit }, { project_id = "" }) {
    commit("app/loading", true, { root: true });
    await departmentsApi.list(project_id).then(
      (response) => {
        commit("setSelectDepartments", response.data.data);
        commit("app/loading", false, { root: true });
      },
      () => {
        commit("app/loading", false, { root: true });
        commit(
          "notifications",
          {
            title: "Error en la búsqueda de departamentos.",
            variant: "danger",
          },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  async getListDepartments({ commit }) {
    commit("app/loading", true, { root: true });
    await departmentsApi.list().then(
      (response) => {
        commit("setItems", response.data.data);
        commit("app/loading", false, { root: true });
      },
      () => {
        commit("app/loading", false, { root: true });
        commit(
          "notifications",
          {
            title: "Error al obtener el listado de departamentos.",
            variant: "danger",
          },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  async getDepartment({ commit }, id) {
    commit("app/loading", true, { root: true });
    await departmentsApi.getDepartment(id).then(
      (response) => {
        commit("setDepartment", response.data);
        commit("app/loading", false, { root: true });
      },
      () => {
        commit("app/loading", false, { root: true });
        commit(
          "notifications",
          { title: "Error al obtener el departamento.", variant: "danger" },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  edit({ commit }, { id, department }) {
    commit("app/loading", true, { root: true });
    departmentsApi.edit(id, department).then(
      () => {
        commit(
          "notifications",
          { title: "Departamento actualizado con éxito.", variant: "success" },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
        commit("app/loading", false, { root: true });
      },
      () => {
        commit("app/loading", false, { root: true });
        commit(
          "notifications",
          { title: "Error al actualizar el departamento.", variant: "danger" },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  create({ commit }, { department }) {
    commit("app/loading", true, { root: true });
    departmentsApi.create(department).then(
      () => {
        commit(
          "notifications",
          { title: "Departamento creado con éxito.", variant: "success" },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
        router.push({ name: "departments" });
        commit("app/loading", false, { root: true });
      },
      () => {
        commit("app/loading", false, { root: true });
        commit(
          "notifications",
          { title: "Error al crear el departamento.", variant: "danger" },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  delete({ commit }, id) {
    commit("app/loading", true, { root: true });
    departmentsApi.delete(id).then(
      () => {
        commit(
          "notifications",
          { title: "Departamento eliminado con éxito.", variant: "success" },
          { root: true }
        );
        location.reload();
      },
      () => {
        commit("app/loading", false, { root: true });
        commit(
          "notifications",
          { title: "Error al eliminar el departamento.", variant: "danger" },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
};

const getters = {
  getDepartment: (state) => {
    return state.department;
  },
  getSelectDepartments: (state) => {
    return state.selectDepartments;
  },
  getItems: (state) => {
    return state.items;
  },
  getTotalItems: (state) => {
    return state.totalItems;
  },
  getShowModalCreateDepartment: (state) => {
    return state.showModalCreateDepartment;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
