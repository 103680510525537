export const config = {
  statusProjectsVariant: {
    inbox: 'light-dark',
    to_do: 'light-danger',
    complete: 'light-success',
    in_progress: 'light-warning',
  },
  statusProjectsText: {
    inbox: 'En espera',
    to_do: 'Pendiente',
    complete: 'Completado',
    in_progress: 'En proceso',
  },
  statusJobsVariants: {
    in_progress: 'light-warning',
    pending: 'light-danger',
    to_do: 'light-danger',
    inbox: 'light-info',
    complete: 'light-success',
    incidence: 'light-danger',
  },
  statusJobsText: {
    in_progress: 'En proceso',
    pending: 'Pendiente',
    to_do: 'Pendiente',
    inbox: 'En espera',
    complete: 'Completado',
    incidence: 'Incidencia',
  },
  statusTasksVariants: {
    in_progress: 'light-warning',
    pending: 'light-info',
    incidence: 'light-danger',
    complete: 'light-success',
  },
  statusTasksText: {
    in_progress: 'En proceso',
    pending: 'Pendiente',
    incidence: 'Incidencia',
    complete: 'Completado',
  },
  statusJourneyDayVariants: {
    in_progress: 'light-warning',
    pending: 'light-info',
    incidence: 'light-danger',
    complete: 'light-success',
  },
  statusJourneyDayText: {
    in_progress: 'En proceso',
    pending: 'Pendiente',
    incidence: 'Incidencia',
    complete: 'Completado',
  },
  nameApp: 'Talleres mata',
  webApiBase: process.env.VUE_APP_ROOT_API,
  placeholder: process.env.VUE_APP_IMAGE_PLACEHOLDER,
  placeholderDocuments: process.env.VUE_APP_IMAGE_PLACEHOLDER_DOCUMENTS,
}
