const state = {
  isShowNotifications: false,
}

const mutations = {
  setShowNotifications(state, value) {
    state.isShowNotifications = value
  },
  notifications(state, {
    title, text = '', variant = 'success', position = 'top-right', icon = '',
  }) {
    let notifications = JSON.parse(localStorage.getItem('notifications'))
    if (!notifications) {
      notifications = []
    }

    const auxNotifications = {}
    auxNotifications.title = title
    auxNotifications.text = text
    auxNotifications.variant = variant
    auxNotifications.position = position
    auxNotifications.icon = icon

    notifications.push(auxNotifications)
    localStorage.setItem('notifications', JSON.stringify(notifications))
  },
  notificationsRemove() {
    const notifications = []

    localStorage.setItem('notifications', JSON.stringify(notifications))
  },
}

const actions = {
}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters,
}
