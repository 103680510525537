import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import notifications from './notifications'
import auth from './auth'
import clients from './clients'
import users from './users'
import departments from './departments'
import projects from './projects'
import jobs from './jobs'
import categories from './categories'
import tasks from './tasks'
import partes from './partes'
import calendar from './calendar'
import config from './config'
import absences from './absences'
import machines from './machines'
import traceabilities from './traceabilities'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    notifications,
    auth,
    clients,
    users,
    departments,
    projects,
    jobs,
    categories,
    tasks,
    partes,
    calendar,
    config,
    absences,
    machines,
    traceabilities,
  },
  strict: process.env.DEV,
})
