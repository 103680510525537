import configApi from '../../api/config-api'

const state = {
  config: {},
}

const mutations = {
  setConfig(state, val) {
    state.config = val
  },
}

const actions = {
  async getConfig({ commit }) {
    commit('app/loading', true, { root: true })
    await configApi.getConfig()
      .then(
        response => {
          commit('setConfig', response.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener la información.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  edit({ commit }, { config }) {
    commit('app/loading', true, { root: true })
    configApi.edit(config)
      .then(
        () => {
          commit('notifications', { title: 'Información actualizada con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
}

const getters = {
  getConfig: state => state.config,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
