import flatpickr from 'flatpickr'

const request = require('flatpickr/dist/l10n/es')

const confLocale = request.default.es
flatpickr.localize(confLocale) // default locale is now Russian

flatpickr.setDefaults(
  {
    dateFormat: 'd/m/Y',
  },
)
