import categoriesApi from '../../api/categories-api'
import router from '@/router'

const state = {
  category: {},
  items: [],
  itemsCreateProject: [],
  selectCategories: [],
  totalItems: 0,
  totalItemsCreateProject: 0,
}

const mutations = {
  setCategory(state, val) {
    state.category = val
  },
  setItems(state, val) {
    state.items = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
  setItemsCreateProject(state, val) {
    state.itemsCreateProject = val
  },
  setSelectCategories(state, val) {
    state.selectCategories = val
  },
}

const actions = {
  async getCategory({ commit }, id) {
    commit('app/loading', true, { root: true })
    await categoriesApi.getCategory(id)
      .then(
        response => {
          commit('setCategory', response.data.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener la categoria.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  getListCategories({ commit }, {
    page, per_page, search = '',
  }) {
    commit('app/loading', true, { root: true })
    categoriesApi.getList(page, per_page, search)
      .then(
        response => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener el listado de categorías.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  getListCategoriesCreateProject({ commit }, { departments = [] }) {
    commit('app/loading', true, { root: true })
    categoriesApi.getListCreateProject(departments)
      .then(
        response => {
          commit('setItemsCreateProject', response.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener el listado de categorías.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getSelectCategories({ commit }, {
    department = '',
  }) {
    await categoriesApi.getList(1, 9999999, '', department)
      .then(
        response => {
          commit('setSelectCategories', response.data.data)
        },
        () => {
          commit('notifications', { title: 'Error al obtener el listado de categorías.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  edit({ commit }, { id, category }) {
    commit('app/loading', true, { root: true })
    categoriesApi.edit(id, category)
      .then(
        () => {
          commit('notifications', { title: 'Categoría actualizada con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar categoría.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  create({ commit }, { category }) {
    commit('app/loading', true, { root: true })
    categoriesApi.create(category)
      .then(
        response => {
          commit('notifications', { title: 'Categoría creada con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          router.push({ name: 'editCategory', params: { id: response.data.data.id } })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al crear categoría.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    categoriesApi.delete(id)
      .then(
        () => {
          commit('notifications', { title: 'Categoría eliminada con éxito.', variant: 'success' }, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al eliminar la categoría.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
}

const getters = {
  getCategory: state => state.category,
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
  getItemsCreateProject: state => state.itemsCreateProject,
  getSelectCategories: state => { return state.selectCategories },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
