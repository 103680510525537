import absencesApi from '../../api/absences-api'
import router from '@/router'

const state = {
  absence: {},
  items: [],
  itemsCreateProject: [],
  totalItems: 0,
  totalItemsCreateProject: 0,
}

const mutations = {
  setAbsence(state, val) {
    state.absence = val
  },
  setItems(state, val) {
    state.items = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
}

const actions = {
  async getAbsence({ commit }, id) {
    commit('app/loading', true, { root: true })
    await absencesApi.getAbsence(id)
      .then(
        response => {
          commit('setAbsence', response.data.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener la ausencia.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  getListAbsences({ commit }, {
    page, per_page, search = '', user_id = '', date_ini = '', date_fin = '', orderBy = '',
  }) {
    commit('app/loading', true, { root: true })
    absencesApi.list(page, per_page, search, user_id, date_ini, date_fin, orderBy)
      .then(
        response => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener el listado de ausencias.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  edit({ commit }, { id, absence }) {
    commit('app/loading', true, { root: true })
    absencesApi.edit(id, absence)
      .then(
        () => {
          commit('notifications', { title: 'Ausencia actualizada con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          location.reload()
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar la ausencia.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  create({ commit }, { absence }) {
    commit('app/loading', true, { root: true })
    absencesApi.create(absence)
      .then(
        response => {
          commit('notifications', { title: 'Ausencia creada con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          location.reload()
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al crear la ausencia.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    absencesApi.delete(id)
      .then(
        () => {
          commit('notifications', { title: 'Ausencia eliminada con éxito.', variant: 'success' }, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al eliminar la ausencia.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
}

const getters = {
  getAbsence: state => state.absence,
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
