import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/traceabilities'

export default {
  getList(page, per_page, search, date_ini, date_fin, project, machine) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      page,
      per_page,
      search,
      date_ini,
      date_fin,
      project_id: project,
      machine_id: machine,
    })
  },
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  edit(id, traceability) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, traceability)
  },
  getMachine(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
}
