import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/schedule'

export default {
  edit(calendar) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, calendar)
  },
  getCalendar() {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}`)
  },
}
