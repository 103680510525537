import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/tasks'

export default {
  edit(id, task) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, task)
  },
  create(task) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, task)
  },
  getJob(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  getList(client = '', page, per_page, search, date_ini = '', date_fin = '', status = '', orderBy = '', project = '') {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      client_id: client,
      project_id: project,
      page,
      per_page,
      search,
      date_ini,
      date_fin,
      status,
      orderBy,
    })
  },
  stadistics(client = '', project = '', date_ini = '', date_fin = '', status = '') {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/totals`, {
      client_id: client,
      project_id: project,
      date_fin,
      date_ini,
      status: status,
    })
  },
}
