import generalApi from '@/api/general-api'
import tasksApi from '../../api/tasks-api'
import router from '../../router'

const state = {
  stadistics: {},
  task: {},
  items: [],
  selectStatus: [],
  totalItems: 0,
}

const mutations = {
  setStadistics(state, val) {
    state.stadistics = val
  },
  setTask(state, val) {
    state.task = val
  },
  setItems(state, val) {
    state.items = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
  setSelectStatus(state, val) {
    state.selectStatus = val
  },
}

const actions = {
  async getTask({ commit }, id) {
    commit('app/loading', true, { root: true })
    await tasksApi.getTask(id)
      .then(
        response => {
          commit('setTask', response.data.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener la tarea.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  getListTasks({ commit }, {
    user = '', page, per_page, search = '', date_ini = '', date_fin = '', status = '', orderBy = '', client = '', project = '',
  }) {
    commit('app/loading', true, { root: true })
    tasksApi.getList(user, page, per_page, search, date_ini, date_fin, status, orderBy, client, project)
      .then(
        response => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener el listado de tareas.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  edit({ commit }, { id, journey }) {
    commit('app/loading', true, { root: true })
    tasksApi.edit(id, journey)
      .then(
        () => {
          commit('notifications', { title: 'Tarea actualizada con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar tarea.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  create({ commit }, { journey }) {
    commit('app/loading', true, { root: true })
    tasksApi.create(journey)
      .then(
        response => {
          commit('notifications', { title: 'Tarea creada con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al crear tarea.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getSelectStatus({ commit }) {
    await generalApi.status('journeytask')
      .then(
        response => {
          console.log('TEST')
          console.log(response)
          commit('setSelectStatus', response.data)
        },
        () => {
          commit('notifications', { title: 'Error en la carga de estados.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getStadistics({ commit }, {
    user = '', client = '', project = '', day = '', status = '',
  }) {
    await tasksApi.stadistics(user, client, project, day, status)
      .then(
        response => {
          commit('setStadistics', response.data)
        },
        () => {
          commit('notifications', { title: 'Error en la carga de estadísticas.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    tasksApi.delete(id)
      .then(
        () => {
          commit('notifications', { title: 'Tarea eliminada con éxito.', variant: 'success' }, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al eliminar la tarea.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  deleteMultiple({ commit }, ids ) {
    commit('app/loading', true, { root: true })
    tasksApi.deleteMultiple(ids)
      .then(
        () => {
          commit('notifications', { title: 'Tareas eliminadas con éxito.', variant: 'success' }, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al eliminar las tareas.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
}

const getters = {
  getStadistics: state => state.stadistics,
  getTask: state => state.task,
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
  getSelectStatus: state => state.selectStatus,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
