import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/journeytasks'

export default {
  stadistics(user = '', client = '', project = '', day = '', status = '') {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/totals`, {
      user_id: user,
      client_id: client,
      project_id: project,
      day: day,
      status: status,
    })
  },
  edit(id, journey) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, journey)
  },
  create(journey) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, journey)
  },
  getTask(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`)
  },
  deleteMultiple(ids) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/multiple`, {
      journeyTasks: ids,
    })
  },
  getList(user = '', page, per_page, search, date_ini = '', date_fin = '', status = '', orderBy = '', client = '', project = '') {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      user_id: user,
      client_id: client,
      project_id: project,
      page,
      per_page,
      search,
      date_ini,
      date_fin,
      status,
      orderBy,
    })
  },
}
