import calendarApi from '../../api/calendar-api'

const state = {
  calendar: {},
}

const mutations = {
  setCalendar(state, val) {
    state.calendar = val
  },
}

const actions = {
  async getCalendar({ commit }) {
    commit('app/loading', true, { root: true })
    await calendarApi.getCalendar()
      .then(
        response => {
          commit('setCalendar', response.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener la información.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  edit({ commit }, { calendar }) {
    commit('app/loading', true, { root: true })
    calendarApi.edit(calendar)
      .then(
        () => {
          commit('notifications', { title: 'Información actualizada con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
}

const getters = {
  getCalendar: state => state.calendar,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
