import axios from 'axios'
import { config } from '../shared/app.config'

export default {
  getListOperarios(page, per_page, search, timestamp, user) {
    return axios.post(`${config.webApiBase}/users/list/operario`, {
      page,
      per_page,
      search,
      timestamp,
      user_id: user,
    })
  },
  generateMonthly(timestamp) {
    return axios.get(`${config.webApiBase}/users/pdf/monthly/${timestamp}`)
  },
  edit(user) {
    return axios.post(`${config.webApiBase}/journeyday/update`, user)
  },
}
