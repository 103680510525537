import axios from 'axios'
import { config } from '../shared/app.config'

const RESOURCE_NAME = '/configuration/company'

export default {
  edit(configdata) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, configdata)
  },
  getConfig() {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}`)
  },
}
