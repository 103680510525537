import axios from "axios";
import { config } from "../shared/app.config";

const RESOURCE_NAME = "/departments";

export default {
  delete(id) {
    return axios.delete(`${config.webApiBase + RESOURCE_NAME}/${id}`);
  },
  edit(id, department) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/${id}`, department);
  },
  create(department) {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}`, department);
  },
  list(project_id = "") {
    return axios.post(`${config.webApiBase + RESOURCE_NAME}/list`, {
      project_id,
    });
  },
  getDepartment(id) {
    return axios.get(`${config.webApiBase + RESOURCE_NAME}/${id}`);
  },
};
