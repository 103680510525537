import axios from 'axios'

axios.interceptors.request.use(config => {
  const token = JSON.parse(localStorage.getItem('token'))

  if (token && token.token) {
    config.headers.Authorization = `${token.token_type} ${token.token}`
  }
  return config
}, err => Promise.reject(err))
