<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { mapMutations } from 'vuex'
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

  },
  watch: {
    '$store.state.theme': function (val) {
      this.toggleClassInBody(val)
    },
    '$vs.rtl': function (val) {
      document.documentElement.setAttribute('dir', val ? 'rtl' : 'ltr')
    },
    loading(val) {
      const appLoading = document.getElementById('loading-bg')
      if (val) {
        if (appLoading) {
          appLoading.style.display = 'block'
          appLoading.style.zIndex = 99999
        }
      } else if (appLoading) {
        appLoading.style.display = 'none'
      }
    },
    showNotifications(val) {
      if (val) {
        const aux = this
        const notifications = JSON.parse(localStorage.getItem('notifications'))
        if (notifications) {
          notifications.forEach(notification => {
            aux.$toast({
              component: ToastificationContent,
              props: {
                title: notification.title,
                icon: notification.icon,
                text: notification.text,
                variant: notification.variant,
              },
            })
          })
        }
        this.$store.commit('notificationsRemove')
        this.$store.commit('setShowNotifications', false)
      }
    },
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    loading() {
      return this.$store.state.app.isLoading
    },
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    showNotifications() {
      return this.$store.state.notifications.isShowNotifications
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
  methods: {
    ...mapMutations({
      recoverUser: 'auth/recoverUser',
      recoverCurrentClient: 'clients/recoverCurrentClient',
    }),
  },
  async created() {
    await this.recoverUser()
    await this.recoverCurrentClient()
    store.commit('setShowNotifications', true)
  },
}
</script>
