import generalApi from '../../api/general-api'
import usersApi from '../../api/users-api'
import projectsApi from "@/api/projects-api";
import router from "@/router";

const state = {
  user: {},
  items: [],
  totalItems: 0,
  selectUsers: [],
  checkJourney: [],
  roles: [],
}

const mutations = {
  setUser(state, val) {
    state.user = val
  },
  setCheckJourney(state, val) {
    state.checkJourney = val
  },
  setItems(state, val) {
    state.items = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
  setSelectUsers(state, val) {
    state.selectUsers = val
  },
  setRoles(state, val) {
    state.roles = val
  },
}

const actions = {
  async selectUsers({ commit }, { roles = '' }) {
    await usersApi.list(1, 999999, '', roles, 1)
      .then(
        response => {
          commit('setSelectUsers', response.data.data)
        },
        () => {
          commit('notifications', { title: 'Error en la búsqueda de usuarios.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  edit({ commit }, { id, user }) {
    commit('app/loading', true, { root: true })
    usersApi.edit(id, user)
      .then(
        () => {
          commit('notifications', { title: 'Usuario actualizado con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar usuario.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getUser({ commit }, id) {
    commit('app/loading', true, { root: true })
    await usersApi.getUser(id)
      .then(
        response => {
          commit('setUser', response.data.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener el usuario.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  getListUsers({ commit }, {
    page, per_page, search = '', roles = ['super_admin', 'admin', 'operario'], active = 1,
  }) {
    commit('app/loading', true, { root: true })
    usersApi.list(page, per_page, search, roles, active)
      .then(
        response => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener el listado de usuarios.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    usersApi.delete(id)
      .then(
        () => {
          commit('notifications', { title: 'Usuario eliminado con éxito.', variant: 'success' }, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al eliminar el usuario.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  create({ commit }, { user }) {
    commit('app/loading', true, { root: true })
    usersApi.create(user)
      .then(
        response => {
          commit('notifications', { title: 'Usuario creado con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          router.push({ name: 'editUser', params: { id: response.data.data.id } })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al crear usuario.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  restartJourney({ commit }, { user }) {
    commit('app/loading', true, { root: true })
    usersApi.restartJourney(user)
      .then(
        response => {
          commit('setCheckJourney', response.data.data)
          commit('notifications', { title: 'Jornada reanudada.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al crear usuario.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async checkJourney({ commit }, { user }) {
    commit('app/loading', true, { root: true })
    await usersApi.checkJourney(user)
      .then(
        response => {
          commit('setCheckJourney', response.data.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al checkear jornada del usuario.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  getRoles({ commit }) {
    commit('app/loading', true, { root: true })
    generalApi.roles()
      .then(
        response => {
          commit('setRoles', response.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener rols.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
}

const getters = {
  getUser: state => state.user,
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
  getSelectUsers: state => { return state.selectUsers },
  getRoles: state => { return state.roles },
  getCheckJourney: state => { return state.checkJourney },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
