import clientsApi from "@/api/clients-api";
import router from "@/router";

const state = {
  client: {},
  currentClient: null,
  showModalClients: false,
  listClients: [],
  selectClients: [],
  items: [],
  totalItems: 0,
};

const mutations = {
  recoverCurrentClient(state) {
    const recoveredCurrentClient = JSON.parse(
      localStorage.getItem("currentClient")
    );
    if (!recoveredCurrentClient) {
      state.currentClient = null;
    } else {
      state.currentClient = recoveredCurrentClient;
    }
  },
  setShowModalClients(state, val) {
    state.showModalClients = val;
  },
  setlistClients(state, val) {
    state.listClients = val;
  },
  setSelectClients(state, val) {
    state.selectClients = val;
  },
  setCurrentClient(state, val) {
    state.currentClient = val;
    localStorage.setItem("currentClient", JSON.stringify(state.currentClient));
    location.reload();
  },
  removeCurrentClient(state) {
    state.currentClient = null;
    localStorage.removeItem("currentClient");
  },
  setItems(state, val) {
    state.items = val;
  },
  setClient(state, val) {
    state.client = val;
  },
  setTotalItems(state, val) {
    state.totalItems = val;
  },
};

const actions = {
  async selectClients({ commit }) {
    await clientsApi.list(1, 9999, "").then(
      (response) => {
        commit("setSelectClients", response.data.data);
      },
      () => {
        commit(
          "notifications",
          { title: "Error en la búsqueda de clientes.", variant: "danger" },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  async searchClients({ commit }, { search = "" }) {
    commit("app/loading", true, { root: true });
    await clientsApi.list(1, 9999, search).then(
      (response) => {
        commit("setlistClients", response.data.data);
        commit("app/loading", false, { root: true });
      },
      (error) => {
        commit("app/loading", false, { root: true });
        commit(
          "notifications",
          { title: "Error en la búsqueda de clientes.", variant: "danger" },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  async getListClients({ commit }, { page, per_page, search = "" }) {
    commit("app/loading", true, { root: true });
    await clientsApi.list(page, per_page, search).then(
      (response) => {
        commit("setItems", response.data.data);
        commit("setTotalItems", response.data.meta.total[1]);
        commit("app/loading", false, { root: true });
      },
      (error) => {
        commit("app/loading", false, { root: true });
        commit(
          "notifications",
          { title: "Error en la búsqueda de clientes.", variant: "danger" },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  async getClient({ commit }, id) {
    commit("app/loading", true, { root: true });
    await clientsApi.getClient(id).then(
      (response) => {
        commit("setClient", response.data.data);
        commit("app/loading", false, { root: true });
      },
      () => {
        commit("app/loading", false, { root: true });
        commit(
          "notifications",
          { title: "Error al obtener el cliente.", variant: "danger" },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  edit({ commit }, { id, client }) {
    commit("app/loading", true, { root: true });
    clientsApi.edit(id, client).then(
      () => {
        commit(
          "notifications",
          { title: "Cliente actualizado con éxito.", variant: "success" },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
        commit("app/loading", false, { root: true });
      },
      () => {
        commit("app/loading", false, { root: true });
        commit(
          "notifications",
          { title: "Error al actualizar el cliente.", variant: "danger" },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  create({ commit }, { client }) {
    commit("app/loading", true, { root: true });
    clientsApi.create(client).then(
      (response) => {
        commit(
          "notifications",
          { title: "Cliente creado con éxito.", variant: "success" },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
        router.push({
          name: "viewClient",
          params: { id: response.data.data.id },
        });
        commit("app/loading", false, { root: true });
      },
      () => {
        commit("app/loading", false, { root: true });
        commit(
          "notifications",
          { title: "Error al crear cliente.", variant: "danger" },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
  delete({ commit }, id) {
    commit("app/loading", true, { root: true });
    clientsApi.delete(id).then(
      () => {
        commit(
          "notifications",
          { title: "Cliente eliminado con éxito.", variant: "success" },
          { root: true }
        );
        location.reload();
      },
      () => {
        commit("app/loading", false, { root: true });
        commit(
          "notifications",
          { title: "Error al eliminar el cliente.", variant: "danger" },
          { root: true }
        );
        commit("setShowNotifications", true, { root: true });
      }
    );
  },
};

const getters = {
  getCurrentClient: (state) => {
    return state.currentClient;
  },
  getSelectClients: (state) => {
    return state.selectClients;
  },
  getListClients: (state) => {
    return state.listClients;
  },
  getListClientsFrecuentes: (state) => {
    return state.listClientsFrecuentes;
  },
  getShowModalClients: (state) => {
    return state.showModalClients;
  },
  getItems: (state) => state.items,
  getTotalItems: (state) => state.totalItems,
  getClient: (state) => state.client,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
