import jobsApi from '../../api/jobs-api'
import router from '../../router'
import generalApi from '@/api/general-api'

const state = {
  stadistics: {},
  job: {},
  items: [],
  selectStatus: [],
  totalItems: 0,
}

const mutations = {
  setStadistics(state, val) {
    state.stadistics = val
  },
  setJob(state, val) {
    state.job = val
  },
  setItems(state, val) {
    state.items = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
  setSelectStatus(state, val) {
    state.selectStatus = val
  },
}

const actions = {
  async getJob({ commit }, id) {
    commit('app/loading', true, { root: true })
    await jobsApi.getJob(id)
      .then(
        response => {
          commit('setJob', response.data.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener el trabajo.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  getListJobs({ commit }, {
    client = '', page, per_page, search = '', date_ini = '', date_fin = '', status = '', orderBy = '', project = '',
  }) {
    commit('app/loading', true, { root: true })
    jobsApi.getList(client, page, per_page, search, date_ini, date_fin, status, orderBy, project)
      .then(
        response => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener el listado de trabajos.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  edit({ commit }, { id, task }) {
    commit('app/loading', true, { root: true })
    jobsApi.edit(id, task)
      .then(
        () => {
          commit('notifications', { title: 'Trabajo actualizado con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar trabajo.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  create({ commit }, { task }) {
    commit('app/loading', true, { root: true })
    jobsApi.create(task)
      .then(
        response => {
          commit('notifications', { title: 'Trabajo creado con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          router.push({ name: 'viewJob', params: { id: response.data.data.id } })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al crear trabajo.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getSelectStatus({ commit }) {
    await generalApi.status('task')
      .then(
        response => {
          commit('setSelectStatus', response.data)
        },
        () => {
          commit('notifications', { title: 'Error en la carga de estados.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    jobsApi.delete(id)
      .then(
        () => {
          commit('notifications', { title: 'Trabajo eliminado con éxito.', variant: 'success' }, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al eliminar el trabajo.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getStadistics({ commit }, { client = '', project = '', date_ini = '', date_fin = '', status = '' }) {
    await tasksApi.stadistics(client, project, date_ini, date_fin, status)
      .then(
        response => {
          commit('setStadistics', response.data)
        },
        () => {
          commit('notifications', { title: 'Error en la carga de estadísticas.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
}

const getters = {
  getStadistics: state => state.stadistics,
  getJob: state => state.job,
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
  getSelectStatus: state => { return state.selectStatus },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
