import machinesApi from '../../api/machines-api'
import router from '@/router'
import projectsApi from '@/api/projects-api'

const state = {
  machine: {},
  items: [],
  selectMachines: [],
  totalItems: 0,
}

const mutations = {
  setMachine(state, val) {
    state.machine = val
  },
  setItems(state, val) {
    state.items = val
  },
  setTotalItems(state, val) {
    state.totalItems = val
  },
  setSelectMachines(state, val) {
    state.selectMachines = val
  },
}

const actions = {
  async getMachine({ commit }, id) {
    commit('app/loading', true, { root: true })
    await machinesApi.getMachine(id)
      .then(
        response => {
          commit('setMachine', response.data.data)
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener la máquina.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  getListMachines({ commit }, {
    page, per_page, search = '', date_ini = '', date_fin = '', project = '',
  }) {
    commit('app/loading', true, { root: true })
    machinesApi.getList(page, per_page, search, date_ini, date_fin, project)
      .then(
        response => {
          commit('setItems', response.data.data)
          commit('setTotalItems', response.data.meta.total[1])
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al obtener el listado de maquinas.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  edit({ commit }, { id, machine }) {
    commit('app/loading', true, { root: true })
    machinesApi.edit(id, machine)
      .then(
        () => {
          commit('notifications', { title: 'Máquina actualizada con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al actualizar máquina.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  create({ commit }, { machine }) {
    commit('app/loading', true, { root: true })
    machinesApi.create(machine)
      .then(
        response => {
          commit('notifications', { title: 'Máquina creada con éxito.', variant: 'success' }, { root: true })
          commit('setShowNotifications', true, { root: true })
          router.push({ name: 'machines' })
          commit('app/loading', false, { root: true })
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al crear máquina.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  delete({ commit }, id) {
    commit('app/loading', true, { root: true })
    machinesApi.delete(id)
      .then(
        () => {
          commit('notifications', { title: 'Máquina eliminada con éxito.', variant: 'success' }, { root: true })
          location.reload()
        },
        () => {
          commit('app/loading', false, { root: true })
          commit('notifications', { title: 'Error al eliminar la máquina.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
  async getSelectMachines({ commit }) {
    await machinesApi.getList(1, 9999999, '')
      .then(
        response => {
          commit('setSelectMachines', response.data.data)
        },
        () => {
          commit('notifications', { title: 'Error al obtener el listado de maquinas.', variant: 'danger' }, { root: true })
          commit('setShowNotifications', true, { root: true })
        },
      )
  },
}

const getters = {
  getMachine: state => state.machine,
  getItems: state => state.items,
  getTotalItems: state => state.totalItems,
  getSelectMachines: state => { return state.selectMachines },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
