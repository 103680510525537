import axios from 'axios'
import { config } from '../shared/app.config'

export default {
  status(type) {
    return axios.post(`${config.webApiBase}/status/list`, {
      type,
    })
  },
  types(type) {
    return axios.post(`${config.webApiBase}/types/list`, {
      type,
    })
  },
  roles() {
    return axios.get(`${config.webApiBase}/roles/list`)
  },
  dashboard_assets(client = '', group = '') {
    return axios.post(`${config.webApiBase}/dashboard/assets`, {
      id_client: client,
      group_id: group
    })
  },
  dashboard_comun(client = '') {
    return axios.post(`${config.webApiBase}/dashboard/comun`, {
      id_client: client
    })
  }
}
